<template>
  <div>
    <div class="box">
      <span>+</span>
      <input class="inp" type="file" ref="sctp" @change="handleUpload" accept="image/*">
      <img v-if="ishowimg" :src="imageSrc" alt="" @click="opimg">
    </div>
    <div v-if="showmg" class="mgbox">
      <img :src="imageSrc" alt="">
      <div>
        <p style="padding-right: 50px;" @click="fh">返回</p>
        <p @click="dele">重置</p>
      </div>
    </div>
  </div>
</template>
<script>
// import draw from './draw.js'
export default {
  data() {
    return {
      canvas: '',
      imageSrc: '',
      showcans: false,
      cts: '',
      ishowimg: false,
      num: 0,
      x: '',
      y: '',
      showmg: false,
      imgname: ''
    }
  },

  props: {
    type: {
      default: ''
    }
  },

  mounted() {
    this._api.publicApi.getImgList(this.type)
        .then(res => {
          let arr = []
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.imageSrc = res.GetListResult[0].FullURL
            this.ishowimg = true
            this.imgname = res.GetListResult[0].UFId
            // for (let i = 0; i < res.GetListResult.length; i++) {
            //   this.imgList.push({
            //     name: res.GetListResult[i].UFId,
            //     url: res.GetListResult[i].FullURL,
            //   })
            // }
          }
        })
  },
  methods: {
    do() {
      // var tool = "huabi";
      // debugger
      // this.canvas = document.querySelector("canvas");
      this.canvas = this.$refs.cans
      var x, y;
      // var canvas = document.querySelector("canvas");
      var ctx = this.canvas.getContext("2d");
      this.cts = ctx
      ctx.fillStyle = 'red'

      let img = new Image()
      img.src = this.imageSrc
      img.width = 1080
      img.onload = () => {
        ctx.drawImage(img, 0, 0, 1080, 1080)
        // console.log(666666)
        this.num = 1
      }

      const _this = this
      this.canvas.onmousedown = function(event) {
        _this.x = event.clientX - this.offsetLeft;
        _this.y = event.clientY - this.offsetTop;
        _this.canvas.addEventListener('mousemove', _this.yd)

        _this.canvas.addEventListener('mouseup', () => {
          _this.canvas.removeEventListener('mousemove', _this.yd)
        })

        /*document.onmousemove = function(event) {
          var x1 = event.clientX - _this.canvas.offsetLeft;
          var y1 = event.clientY - _this.canvas.offsetTop;
          // this.huabi(x, y, x1, y1, ctx);
          ctx.beginPath();
          ctx.globalAlpha = 1;
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#ff0000";
          ctx.moveTo(x, y);
          ctx.lineTo(x1, y1);
          ctx.closePath();
          ctx.stroke();
          ctx.save();
          x = x1;
          y = y1;
        };
      };

      document.onmouseup = function() {
        console.log(555555)
        this.onmousemove = null;
      };*/
      }
    },

    handleUpload(event) {
      if (this.imageSrc) return
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          let bas64 = reader.result
          if (file.size < 1536000) {
            bas64 = bas64.split(',')[1]
            this._api.publicApi.upFiles(bas64, this.type)
                .then(res => {
                  if (res) {
                    this.imageSrc = res.url
                    this.ishowimg = true
                    this.imgname = res.name
                  }
                })
          } else {
            let img = new Image();
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            img.src = bas64
            img.onload = () => {
              let w = img.width, h = img.height
              canvas.width = w
              canvas.height = h
              context.clearRect(0, 0, w, h)
              context.drawImage(img, 0, 0, w, h)
              bas64 = canvas.toDataURL('image/jpeg', 0.2);
              bas64 = bas64.split(',')[1]
              this._api.publicApi.upFiles(bas64, this.type)
                  .then(res => {
                    if (res) {
                      this.imageSrc = res.url
                      this.ishowimg = true
                      this.imgname = res.name
                    }
                  })
              img = null

            }
          }
        };
        reader.readAsDataURL(file);
      }
    },

    opimg() {
      // this.ishowimg = false
      this.showmg = true
    },
    fh() {
      // this.cts.save()
      this.showmg = false
    },

    dele() {
      this._api.publicApi.deleImg(this.imgname)
          .then(res => {
            if (res.DeleteByIdReturn) {
              // console.log(res)
              this.imageSrc = ''
              this.ishowimg = false
              this.showmg = false
            }
          })
    },






    cz() {
      this.num = 0
      this.cts.clearRect(0, 0, 1080, 1080);
      this.showcans = false
      this.ishowimg = false
    },
    yd(event) {
      var x1 = event.clientX - this.canvas.offsetLeft;
      var y1 = event.clientY - this.canvas.offsetTop;
      // this.huabi(x, y, x1, y1, ctx);
      this.cts.beginPath();
      this.cts.globalAlpha = 1;
      this.cts.lineWidth = 2;
      this.cts.strokeStyle = "#ff0000";
      this.cts.moveTo(this.x, this.y);
      this.cts.lineTo(x1, y1);
      this.cts.closePath();
      this.cts.stroke();
      this.cts.save();
      this.x = x1;
      this.y = y1;
    }
  }
};
</script>

<style scoped lang="scss">

.mgbox {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  img {
    width: 100%;
  }
  div {
    position: absolute;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
}



.box {
  height: 40px;
  width: 8vw;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    opacity: 0;
    height: 40px;
  }
  span {
    color: #0000ce;
  }
  img {
    width: 50px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    z-index: 99;
  }
}
.cans-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index:9999;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cansyc {
  width: 0;
  height: 0;
  overflow: hidden;
}

.fn {
  display: flex;
  position: absolute;
  bottom: 20px;
  z-index: 99999;
  color: #ff0000;
  font-weight: bold;
  font-size: 18px;
  p{
    padding: 0 40px;
  }
}
</style>

